import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

import featureImage1 from "../assets/image/l1/png/referralkit-feature-1.png";

const AboutPage = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
        <div className="inner-banner pb-10 pb-lg-20">
          <Container>
            <Row className="justify-content-center mt-md-6 pt-24 pt-lg-29">
              <Col lg="9" xl="8">
                <div className="px-md-12 text-center mb-9 mb-lg-12">
                  <h2 className="title gr-text-2 mb-6 mb-lg-9">Our Features</h2>
                  <p className="gr-text-8 mb-0">
                    Connecting your list to the most effective growth engine.
                  </p>
                </div>
                <div className="hero-btns d-flex flex-column flex-md-row justify-content-md-center mt-11">
                  <a href="https://app.referralkit.co/signup" className="btn btn-primary with-icon gr-hover-y mb-6 mb-md-0 mr-md-7">
                    Start referring today
                  <i className="fas fa-arrow-right gr-text-11"></i>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="content-section pt-10 pt-lg-20 pb-13 pb-lg-27 bg-default-2">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col sm="8" lg="5" xl="6" className="mb-9 mb-lg-0">
                <div className="content-img img-group-2">
                  <img className="img-fluid w-85" src={featureImage1} alt="" />
                </div>
              </Col>
              <Col
                sm="8"
                lg="6"
                xl="5"
                className="offset-lg-1 offset-lg-1 offset-xl-0"
              >
                <div className="content-text">
                  <h2 className="gr-text-3 mb-7 mb-lg-11">
                    Connect your list
                  </h2>
                  <p className="gr-text-8 mb-7 mb-lg-10">
                    ReferralKit will seamlessly integrate with your list publishing platform allowing you to add a unique referral link to your list for all of your subscribers.
                  </p>
                  <p className="gr-text-8 mb-7 mb-lg-10">
                    You'll also be able to integrate our custom script into your list landing page for 
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="content-section pt-10 pt-lg-27 pb-13 pb-lg-27 bg-white">
          <Container>
            <Row className="align-items-center justify-content-between">
              <Col sm="8" lg="5" xl="6" className="mb-9 mb-lg-0">
                <div className="content-text">
                  <h2 className="gr-text-3 mb-7 mb-lg-11">
                    High skilled coders <br className="d-none d-lg-block" />{" "}
                    from worldwide.
                  </h2>
                  <p className="gr-text-8 mb-7 mb-lg-10">
                    We share common trends and strategies for improving your
                    rental income and making sure you stay in high demand of
                    service.{" "}
                  </p>
                  <p className="gr-text-8 mb-0">
                    With lots of unique blocks, you can easily build a page
                    without coding. Build your next landing page. With lots of
                    unique blocks, you can easily build a page without coding
                    any other page.
                  </p>
                </div>
              </Col>
              <Col
                sm="8"
                lg="6"
                xl="5"
                className="offset-lg-1 offset-lg-1 offset-xl-0"
              >
                <div className="content-img img-group-2">
                  <img className="img-fluid w-100" src={featureImage1} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default AboutPage;
